<template>
  <l-map
    ref="map"
    class="mapholder"
    v-bind:class="mapclass"
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    style="height: 100%"
    @update:center="centerUpdate"
    @update:zoom="zoomUpdate"
  >
    <l-tile-layer :url="url" :attribution="attribution" />

    <!-- <Vue2LeafletHeatmap v-for="(layer,index) in heatLayers" v-bind:key="index" :gradient="layer.gradient" :lat-lng="layer.latlngs" :radius="30" :min-opacity=".15" :max-zoom="10" :blur="40"></Vue2LeafletHeatmap> -->

    <l-circle-marker
      :lat-lng="latLngFromVals(marker.lat, marker.lng)"
      v-for="marker in markers"
      v-bind:key="marker.id"
      color="transparent"
      v-bind:fillColor="marker.color"
      v-bind:fillOpacity="marker.weight"
      v-bind:radius="10"
    ></l-circle-marker>
  </l-map>
</template>

<script>
// import Vue2LeafletHeatmap from "../ui/Vue2LeafletHeatmap";
import { Icon, latLng } from "leaflet";
import { LMap, LTileLayer, LCircleMarker } from "vue2-leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: { LMap, LTileLayer, LCircleMarker },
  name: "Map",
  props: {
    centerLat: String,
    centerLng: String,
    markers: Array,
    heatLayers:Array,
    showCenterMark: Boolean,
    mapclass: String,
    showMap: Boolean,
    probeLocation: Boolean,
  },
  data() {
    return {
      zoom: 8,
      center: latLng(this.centerLat, this.centerLng),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 4,
      mapOptions: {
        zoomSnap: 0.5,
      }
    };
  },
  watch: {
    centerLat: function (val) {
      this.centerSet(latLng(val, this.centerLng)); // update map
    },
    centerLng: function (val) {
      this.centerSet(latLng(this.centerLat, val)); // update map
    }
  },
  methods: {
    centerSet(center) {
      // center was set from outside (parent component)
      this.center = center;
      this.zoom = 14;
      this.$refs.map.mapObject.invalidateSize();
      this.$emit("centerchanged", center);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(latlng) {
      this.$emit("centerchanged", latlng);
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick(id) {
      this.$emit("markerselected", id);
    },
    latLngFromVals(lat, lng) {
      try {
        return latLng(lat, lng);
      } catch (e) {
        //
      }
      return latLng(this.centerLat, this.centerLng);
    },
    getLocation() {
      if (!("geolocation" in navigator)) {
        alert("Toegang tot je locatie is uitgeschakeld of niet mogelijk.");
      }

      navigator.geolocation.getCurrentPosition(
        (pos) => {
          console.log(pos);
          this.centerSet(latLng(pos.coords.latitude, pos.coords.longitude));
        },
        (err) => {
          console.log(err);
          alert("Toegang tot je locatie is uitgeschakeld of niet mogelijk.");
        }
      );
    },
  },
  mounted: function () {
    console.log(this.center);
    if (this.probeLocation) this.getLocation();
  },
};
</script>
