import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueLazyload from 'vue-lazyload'

import 'leaflet/dist/leaflet.css';
import 'vue-select/dist/vue-select.css';

// FA icons
library.add(faUser)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// lazy load
Vue.use(VueLazyload)

// state
export const appState = new Vue({
  data: {
    bar: false,
    loading: false
  }
})

// VUE options
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
