<template>
  <div class="page fw">
      <ui-map
        v-if="centerLat && centerLng"
        v-bind:center-lat="centerLat"
        v-bind:center-lng="centerLng"
        v-bind:markers="items"
        v-bind:heat-layers="heatLayers"
        v-on:centerchanged="mapcenterchanged"
        v-on:markerselected="markerselected"
      ></ui-map>

    <div id="legendbar" v-bind:class="selectedGroup.groupName ? 'extra' : ''">
      <span v-for="group in selectedSet.groups" v-bind:key="group.groupName">
        <i v-bind:style="{ backgroundColor: group.color }"></i>
        <a
          href="#"
          @click.stop="showGroup(group)"
          v-bind:style="{
            fontWeight:
              selectedGroup.groupName == group.groupName ? 'bold' : 'normal',
          }"
          >{{ group.groupName }}</a
        >
      </span>
      <p id="speciesbar">
        <span
          v-for="(species, index) in selectedGroup.species"
          v-bind:key="index"
        >
          <a
            href="#"
            @click.stop="toggleSpecies(species)"
            v-bind:class="{ dim: speciesDisabled(species) }"
          >
            <font-awesome-icon
              icon="check"
              v-bind:style="{ opacity: speciesDisabled(species) ? 0 : 1 }"
            ></font-awesome-icon>
            {{ species }}</a
          >
        </span>
      </p>
    </div>
    <div id="filterbar">
      <p>Classificatie:</p>
      <div class="toggler">
        <a
          v-for="set in sets"
          v-bind:key="set.name"
          @click.stop="selectSet(set)"
          v-bind:class="set.name == selectedSet.name ? 'active' : ''"
          >{{ set.name }}</a
        >
      </div>
      <p>Periode:</p>
      <div class="toggler">
        <a
          v-for="(period, index) in periods"
          v-bind:key="index"
          @click.stop="selectPeriod(period)"
          v-bind:class="period.selected ? 'active' : ''"
          >{{ period.name }}</a
        >
      </div>
      <a href="#" @click.stop="playTimeLapse()">
        <font-awesome-icon icon="play-circle"></font-awesome-icon>
      </a>
    </div>
  </div>
</template> 

<script>
import UiMap from "./ui/Map";
import axios from "axios";
import { appState } from "../main";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlayCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
library.add(faPlayCircle, faCheck);

export default {
  components: { UiMap },
  name: "Home",
  props: {},
  data() {
    return {
      state: appState,
      items: [],
      heatLayers: [],
      showInput: false,
      mapcenter: {},
      centerLat: "52.092876",
      centerLng: "5.104480",
      selectedSet: {},
      selectedGroup: {},
      periods: [
        { name: "2001-2021", value: "2001-2021", selected: true },
        { name: "2011-2015", value: "2011-2015" },
        { name: "2016-2020", value: "2016-2020" },
        { name: "2011-2020", value: "2011-2020" },
      ],
      sets: [""],
      disabledSpecies: [],
    };
  },
  watch: {
    items: function (val) {
      var layers = {};

      val.forEach((item) => {
        var name = item.name.replaceAll(" ", "_");
        if (!layers[name])
          layers[name] = {
            latlngs: [],
            gradient: { 0.4: "blue", 0.65: "lime", 1: item.color },
          };
        layers[name].latlngs.push([item.lat, item.lng, item.weight]);
      });

      for (const layer in layers) {
        this.heatLayers.push(layers[layer]);
        console.log(layer);
      }
      this.$forceUpdate();
    },
  },
  methods: {
    load: function (callback) {
      var selectedPeriod = this.getSelectedPeriod();

      var disabledSpecies = [];
      if (this.selectedSet) {
        this.selectedSet.groups.forEach((group) => {
          group.species.forEach((species) => {
            if (this.speciesDisabled(species, group))
              disabledSpecies.push(species);
          });
        });
      }

      axios
        .get(
          "/api/public/uurhok?period=" +
            selectedPeriod.value +
            "&setName=" +
            this.selectedSet.name +
            "&disable=" +
            disabledSpecies
        )
        .then((response) => {
          this.items = response.data;
          this.state.loading = false;
          if (callback) callback();
        });
    },
    mapcenterchanged: function (latlng) {
      this.mapcenter = latlng;
    },
    markerselected: function (id) {
      this.editSpot(id);
    },
    selectPeriod: function (period) {
      this.state.loading = true;
      this.periods.forEach((period) => (period.selected = false));
      this.periods.filter(
        (item) => item.name == period.name
      )[0].selected = true;
      this.load();
    },
    selectSet: function (set) {
      this.state.loading = true;
      this.selectedSet = set;
      this.load();
    },
    showGroup: function (group) {
      if (this.selectedGroup.groupName == group.groupName)
        this.selectedGroup = {};
      else this.selectedGroup = group;
    },
    getSelectedPeriod: function () {
      return this.periods.filter((item) => item.selected)[0];
    },
    toggleSpecies: function (species) {
      var obj = (species + "_disabled").replaceAll(" ", "_");
      this.selectedGroup[obj] = !this.selectedGroup[obj];
      this.$forceUpdate();
      this.load(); // reload to apply filter
      console.log(this.sets);
    },
    speciesDisabled: function (species, group) {
      var obj = (species + "_disabled").replaceAll(" ", "_");
      return (group || this.selectedGroup)[obj] ?? false;
    },
    playTimeLapse: function () {
      var period = this.getSelectedPeriod();
      var years = period.name.split("-");
      var yearMin = parseInt(years[0]);
      var yearMax = parseInt(years[1]);

      var me = this;

      var lapseYears = function (min, max, current) {
        var startTime = new Date();

        var periodName = min + "-" + current;
        period.value = periodName;

        me.load(() => {
          period.name = periodName;
          if (current == max) return;
          var elapsedMs = new Date() - startTime;
          var delay = 1000 - elapsedMs;
          console.log(elapsedMs);
          window.setTimeout(
            () => lapseYears(min, max, current + 1),
            delay > 0 ? delay : 0
          );
        });
      };

      this.state.loading = true;
      lapseYears(yearMin, yearMax, yearMin);
    },
  },
  mounted: function () {
    // get sets
    axios.get("/api/public/sets").then((response) => {
      this.sets = response.data;
      this.selectedSet = this.sets[0];
      this.state.loading = true;
      this.load(() => {
        window.setTimeout(() => {
          this.state.bar = true;
        }, 100);
      });
    });
  },
};
</script>
