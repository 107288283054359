<template>
  <div id="app" v-bind:class="state.loading ? 'pulse' : ''">
    <div id="nav" v-bind:class="!state.bar ? 'closed' : ''">
      <div class="togglebar" @click.stop="togglebar()">
        <span v-if="state.bar"
          ><font-awesome-icon icon="chevron-left"></font-awesome-icon
        ></span>
        <span v-if="!state.bar"
          ><font-awesome-icon icon="info"></font-awesome-icon
        ></span>
      </div>

      <p class="logos">
        <a
          href="https://www.vangstenregistratie.nl"
          target="_blank"
          title="De hengelmonitor is onderdeel van Vangstenregistratie.nl"
        >
          <img
            src="https://www.vangstenregistratie.nl/images/icon-svr-square.png"
          />
        </a>
        <a
          href="https://vaartsoftware.nl"
          target="_blank"
          title="VAART software ontwikkelde de hengelmonitor"
        >
          <img
            src="https://vaartsoftware.nl/wp-content/uploads/2021/05/Vaart-Software-RGB-logo.jpg"
          />
        </a>
      </p>

      <h1>Hengelmonitor.nl</h1>

      <p>
        Hengelmonitor.nl visualiseert data van hengelvangstregistraties en
        waarnemingen van sportvissers voor vis- en wateronderzoek in Nederland.
      </p>

      <p>
        De gegevens zijn afkomstig uit
        <a href="https://www.vangstenregistratie.nl" target="_blank"
          >Vangstenregistratie.nl</a
        >. Sinds 2001 houden meer dan 30.000 sportvissers hier een persoonlijk
        vangstenlogboek bij, met instemming om de gegevens te delen voor
        onderzoek (en de mogelijkheid om dat
        <a href="https://www.vangstenregistratie.nl/onderzoek"
          >uit te schakelen</a
        >).
      </p>

      <p>
        Het delen en inzetten van hengelvangstdata voor vis- en wateronderzoek
        is een belangrijke doelstelling van Vangstenregistratie.nl.
      </p>

      <h2>Kaart preview</h2>
      <p>
        De kaart hiernaast visualiseert de beschikbare data, getransformeerd
        naar een aantal voorgedefinieerde classificaties. Deze kaartdata is
        (grof!) samengevoegd per uurhok en dient puur als preview.
      </p>

      <h2>Beschikbaar voor onderzoek</h2>
      <p>
        De gedetailleerde data zijn via deze site op te vragen, analyseren en
        downloaden. Ook zijn alerts in te stellen op specifieke soorten in een
        gebiedsselectie.
      </p>
      <p>
        Alleen <strong>gevalideerde organisaties</strong> krijgen toegang tot
        deze data en functionaliteit. In aanmerking komen onder meer
        hengelsportverenigingen, waterschappen en onderzoeksbureaus.
      </p>
      <p>
        Deze afgeschermde data zijn geanonimiseerd en samengevoegd per jaargang,
        vissoort en lengteklasse. Er zijn dus geen gegevens te herleiden naar
        personen of exacte vangsten.
      </p>
      <p>
        Voor meer informatie of om uw organisatie aan te melden, stuur een
        e-mail naar
        <a href="mailto:info@vaartsoftware.nl">info@vaartsoftware.nl</a>.
      </p>

      <h2>Ontwikkeling</h2>
      <p>
        Hengelmonitor.nl en Vangstenregistratie.nl worden ontwikkeld door
        <a href="https://vaartsoftware.nl" target="_blank">VAART software</a>.
      </p>

      <div id="nav-footer">
        <button type="button" class="btn" @click.stop="togglebar()">
          Sluit venster
        </button>
      </div>
    </div>

    <div id="content">
      <router-view />
    </div>
  </div>
</template>

<style>
@import "./assets/styles/style-default.css";
@import "./assets/styles/style-desktop.css";
</style> 

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { appState } from "./main";

import {
  faChevronLeft,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";

library.add(faChevronLeft, faInfo);

export default {
  components: {},
  name: "App",
  data() {
    return {
      bar: false,
      state: appState,
    };
  },
  methods: {
    reload: function () {
      window.location.href = "/";
    },
    togglebar: function () {
      this.state.bar = !this.state.bar;
    },
  },
  mounted: function () {},
};
</script>
